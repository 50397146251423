
import { Component, Vue } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { ValidationObserver } from "vee-validate";
import { Utils } from "@/services/utils";
import JnProgress from "@/views/common/Progress.vue";
import JnChangePassword from "@/views/auth/ChangePassword.vue";
import JnResetPassword from "@/views/auth/ResetPassword.vue";
import EventBus from "@/services/event.bus";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import {
  FcmTopicsPlatform,
  FcmTopicsAreeTematicheList,
  AdminCookie,
} from "@/models/enums.model";
import { getToken } from "firebase/messaging";
import getMessaging from "@/firebase";

@Component({
  components: {
    ValidationObserver,
    JnProgress,
    JnChangePassword,
    JnResetPassword,
  },
})
export default class JnLogin extends Vue {
  resetted = false;

  showLogin = false;
  showResetPassword = false;

  email = "";
  password = "";
  passwordProgress = 0;
  showPassword = false;
  alert = false;
  alertMessage = "";
  alertTimeout = 30000;
  loading = false;

  created() {
    EventBus.$on("reset-password", async () => {
      this.resetted = true;
      await Utils.timeout(5000);
      window.history.go();
    });
  }

  progress() {
    const regularExpression =
      /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    this.passwordProgress = Math.min(80, this.password.length * 10);
    if (regularExpression.test(this.password)) {
      this.passwordProgress = this.passwordProgress + 20;
    }
    return this.passwordProgress;
  }

  get progressPassword() {
    this.passwordProgress = this.progress();
    return this.passwordProgress;
  }

  get colorPassword() {
    if (this.passwordProgress < 80) {
      return "error";
    } else if (this.passwordProgress >= 80 && this.passwordProgress < 100) {
      return "warning";
    } else {
      return "success";
    }
  }

  get passwordOk() {
    const regularExpression =
      /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return regularExpression.test(this.password) && this.password.length >= 8;
  }

  cancel() {
    this.showLogin = false;
  }

  closeReset() {
    this.showLogin = false;
    window.history.go();
  }

  get username() {
    return this.email;
  }

  async checkToken(tokens: any, token: string) {
    console.log("Login.checkToken: checking token '" + token + "'");
    let found = false;
    if (tokens.status != 404) {
      for (const t of tokens) {
        if (t.token == token) {
          found = true;
        }
      }
    }
    if (!found) {
      console.log("Login.checkToken: new token, resubscribing...'");
      await JnService.fcmRegisterTopic(token, FcmTopicsPlatform.PORTAL);
      let cookie = undefined;
      for (const topic of FcmTopicsAreeTematicheList) {
        cookie = this.$cookies.get("fcm-topic-" + topic);
        if (cookie) {
          if (cookie === "true") {
            await JnService.fcmRegisterTopic(token, topic);
          }
        } else {
          this.$cookies.set("fcm-topic-" + topic, false, {
            expires: new Date("9999-12-31T23:59:59.999Z"),
          });
        }
      }
    }
  }

  async getMaxNumSessions(username: string): Promise<number> {
    const user = await JnService.getCrmUser(username, undefined);
    if (user) {
      const purchaseOrder = await JnService.getActivePurchaseOrder(user.id);
      if (purchaseOrder && purchaseOrder.maxNumSession) {
        return Number(purchaseOrder.maxNumSessions);
      }
      return Number(process.env.VUE_APP_MAX_NUM_SESSIONS);
    }
    return Number(process.env.VUE_APP_MAX_NUM_SESSIONS);
  }

  async login() {
    this.loading = true;
    const maxNumSessions = await this.getMaxNumSessions(this.email);
    const res = await JnService.login(
      this.email,
      this.password,
      maxNumSessions,
      Number(process.env.VUE_APP_PRODUCT_ID)
    );
    let user = undefined;
    let service = undefined;
    let purchaseOrder = undefined;
    if (res.code == 200) {
      this.$cookies.set("jwtToken", res.data.jwtToken, {
        expires: process.env.VUE_APP_SESSION_TIMEOUT + "h",
      });
      if (res.data.change_required) {
        this.loading = false;
        sessionStorage.setItem("changeRequired", res.data.change_required);
        this.showResetPassword = true;
      } else {
        user = await JnService.getCrmUser(this.email, undefined);
        purchaseOrder = await JnService.getActivePurchaseOrder(user.id);
        console.log(purchaseOrder);
        this.$cookies.set("username", this.email, {
          expires: process.env.VUE_APP_SESSION_TIMEOUT + "h",
        });
        this.$cookies.set(AdminCookie, user.admin, {
          expires: process.env.VUE_APP_SESSION_TIMEOUT + "h",
        });
        this.$cookies.set("SESSIONID", res.data.idSession, {
          expires: process.env.VUE_APP_SESSION_TIMEOUT + "h",
        });
        this.$cookies.set("vtiger_user", JSON.stringify(user), {
          expires: process.env.VUE_APP_SESSION_TIMEOUT + "h",
        });
        if (purchaseOrder) {
          this.$cookies.set("vtiger_purchase_order", JSON.stringify(purchaseOrder), {
            expires: process.env.VUE_APP_SESSION_TIMEOUT + "h",
          });
        }
        sessionStorage.setItem("vtiger_user", JSON.stringify(user));
        if (purchaseOrder) {
          EventBus.$emit("logged-paywall-ok");
          sessionStorage.setItem("serviceId", purchaseOrder.serviceId);
          if (purchaseOrder.serviceId != "") {
            service = await JnService.getService(purchaseOrder.serviceId);
          }
        } else {
          EventBus.$emit("logged-paywall-not-ok");
        }
      }

      const crmInfo = new CrmInfo(user, purchaseOrder, service);
      this.$store.commit("crmInfo", crmInfo);
      EventBus.$emit("check-authentication");
      EventBus.$emit("update-profile");
      EventBus.$emit("reset-real-time");
      EventBus.$emit("reset-ricerca-banca-dati");
      EventBus.$emit("reset-ricerca-news");
      EventBus.$emit("reset-ricerca-area-tematica");
      EventBus.$emit("reset-ricerca-global", []);

      /**
       * FCM Token Management
       */
      const messaging = getMessaging();
      const tokens = await JnService.fcmGetTokens(
        user.username,
        FcmTopicsPlatform.PORTAL
      );
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
        });
        await this.checkToken(tokens, token);
        await JnService.fcmSaveToken(
          user.username,
          FcmTopicsPlatform.PORTAL,
          token
        );
        console.log("Login.login(fcm-token): " + token);
      } catch (err) {
        console.log("Login.login(err): " + JSON.stringify(err));
      }
    } else if (res.code == 403) {
      this.alert = true;
      this.alertMessage =
        "Hai raggiunto il numero massimo di accessi contemporanei consentiti ai contenuti riservati. <br/>Per aumentare il limite puoi acquistare un'estensione di licenza. <br/>Contatta subito il <a href='/contatti'>servizio clienti</a>.";
    } else {
      this.alert = true;
      this.alertMessage = res.data;
    }
    this.loading = false;
  }
}
