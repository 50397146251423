
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import JnMenuItem from "@/views/home/MenuItem.vue";
import JnDotsMenu from "@/views/home/DotsMenu.vue";
import JnLogin from "@/views/auth/Login.vue";
import { Menu } from "@/models/menu.model";
import { JnService } from "@/services/jn.service";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import { User } from "@/models/crm/user.model";
import EventBus from "@/services/event.bus";
import JnSearchResults from "@/views/search/SearchResults.vue";
import JnProgress from "@/views/common/Progress.vue";
import JnWhatsAppContactMenu from "@/views/common/WhatsAppContactMenu.vue";
import { AdminCookie } from "@/models/enums.model";
import { SearchTypes } from "@/models/enums.model";
import JnAutocompleteSearch from "@/views/common/Autocomplete.vue";
import { Purchase } from "@/models/crm/purchase.model";

@Component({
  components: {
    JnMenuItem,
    JnLogin,
    JnDotsMenu,
    JnSearchResults,
    JnProgress,
    JnWhatsAppContactMenu,
    JnAutocompleteSearch,
  },
})
export default class JnAppBar extends Vue {
  public showLogin = false;

  alert = false;
  alertSpellCorrection = false;
  alertMessage = "";
  alertSpellCorrectionMessage = "";
  alertTimeout = 30000;

  loading = false;

  menuSelected = 0;
  authenticated = false;
  admin = false;
  paywallOk = false;

  showAlertNumMaxSessions = false;

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  menuHome = new Menu({
    name: "HOME",
    routeName: "Home",
    routePath: "/",
    items: undefined,
    icon: undefined,
    index: 0,
    tooltip: undefined,
  });
  menuChiSiamo = new Menu({
    name: "CHI SIAMO",
    routeName: "ChiSiamo",
    routePath: "/chi-siamo",
    items: undefined,
    icon: undefined,
    index: 1,
    tooltip: undefined,
  });

  /**
   * DOTS MENU
   */
  menuDotsItems = [
    new Menu({
      name: "Agenda",
      routeName: "Agenda",
      routePath: "/agenda",
      items: undefined,
      icon: undefined,
      index: 0,
      tooltip: undefined,
    }),
    new Menu({
      name: "Ricerche On Demand",
      routeName: "RicercheOnDemand",
      routePath: "/ricerche-on-demand",
      items: undefined,
      icon: undefined,
      index: 1,
      tooltip: undefined,
    }),
  ];

  menuDots = new Menu({
    name: "",
    routeName: "",
    routePath: "",
    items: this.menuDotsItems,
    icon: undefined,
    index: -1,
    tooltip: undefined,
  });
  /**
   * AREE TEMATICHE
   */
  menuAreeTematicheItems = [
    new Menu({
      name: "Diritto Societario",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-societario",
      items: undefined,
      icon: "coins",
      index: 0,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto Fallimentare",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-fallimentare",
      items: undefined,
      icon: "calculator",
      index: 1,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto Tributario",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-tributario",
      items: undefined,
      //icon: '/img/aree-tematiche-white/area-tematica-diritto-tributario.png',
      icon: "percentage",
      index: 2,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto Penale",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-penale",
      items: undefined,
      icon: "balance-scale",
      index: 3,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto Amministrativo",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-amministrativo",
      items: undefined,
      icon: "user-graduate",
      index: 4,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto del Lavoro",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-del-lavoro",
      items: undefined,
      //icon: '/img/aree-tematiche-white/area-tematica-diritto-lavoro.png',
      icon: "tools",
      index: 5,
      tooltip: undefined,
    }),
    new Menu({
      name: "Assicurazioni e Risarcimento Danni",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/assicurazioni",
      items: undefined,
      //icon: '/img/aree-tematiche-white/area-tematica-assicurazioni.png',
      icon: "hourglass",
      index: 6,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto di Famiglia e Successioni",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-famiglia",
      items: undefined,
      //icon: '/img/aree-tematiche-white/area-tematica-diritto-di-famiglia.png',
      icon: "dna",
      index: 7,
      tooltip: undefined,
    }),
    new Menu({
      name: "Protezione dei dati personali",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/privacy",
      items: undefined,
      icon: "comment-dots",
      index: 8,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto delle nuove tecnologie",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/diritto-nuove-tecnologie",
      items: undefined,
      //icon: '/img/aree-tematiche-white/area-tematica-diritto-nuove-tecnologie.png',
      icon: "sync",
      index: 9,
      tooltip: undefined,
    }),
    new Menu({
      name: "Mercati immobiliari",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/mercati-immobiliari",
      items: undefined,
      icon: "hotel",
      index: 10,
      tooltip: undefined,
    }),
    new Menu({
      name: "Contrattualistica di diritto privato",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/contrattualistica-diritto-privato",
      items: undefined,
      icon: "book-open",
      index: 11,
      tooltip: undefined,
    }),
    new Menu({
      name: "Bancario e Mercati finanziari",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/bancario-mercati-finanziari",
      items: undefined,
      icon: "landmark",
      index: 12,
      tooltip: undefined,
    }),
    new Menu({
      name: "Diritto Processuale Telematico",
      routeName: "AreaTematicaInterna",
      routePath: "/aree-tematiche/processo-telematico",
      items: undefined,
      icon: "book",
      index: 13,
      tooltip: undefined,
    }),
  ];

  // AI Menus
  menuAiItems: Menu[] = []

  menuAreeTematiche = new Menu({
    name: "AREE TEMATICHE",
    routeName: "AreeTematiche",
    routePath: "/aree-tematiche",
    items: this.menuAreeTematicheItems,
    icon: undefined,
    index: 2,
    tooltip: undefined,
  });
  menuMassimario = new Menu({
    name: "MASSIMARIO",
    routeName: "Massimario",
    routePath: "/massimario",
    items: undefined,
    icon: undefined,
    index: 3,
    tooltip: undefined,
  });
  menuFormulario = new Menu({
    name: "FORMULARI",
    routeName: "Formulario",
    routePath: "/formulario",
    items: undefined,
    icon: undefined,
    index: 4,
    tooltip: undefined,
  });
  menuNews = new Menu({
    name: "NEWS",
    routeName: "News",
    routePath: "/news",
    items: undefined,
    icon: undefined,
    index: 5,
    tooltip: undefined,
  });

  menuBancaDati = new Menu({
    name: "BANCA DATI",
    routeName: "BancaDati",
    routePath: "/banca-dati",
    items: undefined,
    icon: undefined,
    index: 6,
    tooltip: undefined,
  });

  menuCodici = new Menu({
    name: "CODICI",
    routeName: "Codici",
    routePath: "/codici",
    items: undefined,
    icon: undefined,
    index: 7,
    tooltip: undefined,
  });

  menuRealTime = new Menu({
    name: "REAL TIME",
    routeName: "RealTime",
    routePath: "/realtime",
    items: undefined,
    icon: undefined,
    index: 8,
    tooltip: undefined,
  });

  menuPubblicazioni = new Menu({
    name: "PUBBLICAZIONI",
    routeName: "Pubblicazioni",
    routePath: "/pubblicazioni",
    items: undefined,
    icon: undefined,
    index: 9,
    tooltip: undefined,
  });
  menuVideo = new Menu({
    name: "VIDEO",
    routeName: "Video & Podcast",
    routePath: "/video",
    items: undefined,
    icon: undefined,
    index: 10,
    tooltip: undefined,
  });
  menuAgenda = new Menu({
    name: "AGENDA",
    routeName: "Agenda",
    routePath: "/agenda",
    items: undefined,
    icon: undefined,
    index: 11,
    tooltip: undefined,
  });

  menuAbbonamenti = new Menu({
    name: "ABBONAMENTI",
    routeName: "AbbonamentiAdmin",
    routePath: "/abbonamenti",
    items: undefined,
    index: 12,
    icon: undefined,
    tooltip: undefined,
  });
  menuAI = new Menu({
    name: "AI",
    routeName: "AI",
    routePath: "/ai",
    items: this.menuAiItems,
    icon: undefined,
    index: 13,
    tooltip: undefined,
  });

  goToProfile() {
    this.$router.push("/area-personale");
  }

  goToHome() {
    try {
      if (this.$route.path == "/") {
        window.location.href = "/";
      } else {
        this.$router.push("/");
      }
    } catch (err) {
      console.log("JnAppBar: route to same page skipped");
    }
  }

  public async login() {
    await this.clearSession();
    this.showLogin = true;
  }

  public profile() {
    this.showLogin = true;
  }

  public async logout() {
    await this.clearSession();
    this.goToHome();
  }

  public async clearSession() {
    const username = this.$cookies.get("username") as string;
    const idSession = this.$cookies.get("SESSIONID") as string;
    if (username && idSession) {
      await JnService.logout(
        username,
        idSession,
        Number(process.env.VUE_APP_PRODUCT_ID)
      );
      this.$store.commit("crmInfo", undefined);
      sessionStorage.removeItem("serviceId");
      this.$cookies.remove("username");
      this.$cookies.remove(AdminCookie);
      this.$cookies.remove("SESSIONID");
      this.$cookies.remove("vtiger_purchase_order");
      this.$cookies.remove("vtiger_user");
    }
  }

  async checkAndSearch() {
    EventBus.$emit("check-spell", SearchTypes.ALL_HEADER);
  }
  goToSearch() {
    this.$router.push("/general-search");
  }
  updateAiMenu() {
    this.menuAiItems = [
      new Menu({
        name: "JuraNews AI",
        routeName: "AiChat",
        routePath: "/ai/chat",
        items: undefined,
        icon: "comments",
        index: 0,
        tooltip: undefined,
      })
    ]
    if (this.admin) {
      this.menuAiItems.push(new Menu({
        name: "Verifica Testi AI",
        routeName: "AiAnalysisReview",
        routePath: "/ai/review",
        items: undefined,
        icon: "check",
        index: 1,
        tooltip: undefined,
      }))
    }
    this.menuAI = new Menu({
      name: "AI",
      routeName: "AI",
      routePath: "/ai",
      items: this.menuAiItems,
      icon: undefined,
      index: 13,
      tooltip: undefined,
    });
  }
  async mounted() {
    EventBus.$on("check-authentication", () => {
      this.authenticated = this.$cookies.get("username") ? true : false;
      this.admin = this.$cookies.get(AdminCookie) === "true";
      if (!this.authenticated) {
        this.clearSession();
      }
      this.updateAiMenu();
    });
    EventBus.$on("logged-paywall-ok", async () => {
      this.paywallOk = true;
      this.updateAiMenu();
    });
    const usernameFromCookie = this.$cookies.get("username");
    const changeRequired = sessionStorage.getItem("changeRequired");

    if (usernameFromCookie && !changeRequired) {
      this.authenticated = true;
      const userCookie = this.$cookies.get("vtiger_user") as User;
      const purchaseCookie = this.$cookies.get("vtiger_purchase_order") as Purchase;
      const user = userCookie ? userCookie as User: undefined;
      const purchase = purchaseCookie ? purchaseCookie as Purchase: undefined;
      /*const user = await JnService.getCrmUser(
        String(usernameFromCookie),
        undefined
      );
      this.admin = this.$cookies.get(AdminCookie) === "true";
      const purchase = await JnService.getActivePurchaseOrder(user.id);*/
      let crmInfo = undefined;
      console.log("AppBar.mounted(purchase): " + JSON.stringify(purchase));
      if (user) {
        if (purchase) {
          const service = await JnService.getService(purchase.serviceId);
          crmInfo = new CrmInfo(user, purchase, service);
          this.paywallOk = true
        } else {
          crmInfo = new CrmInfo(user, undefined, undefined);
        }
      }
      this.$store.commit("crmInfo", crmInfo);
    } else if (usernameFromCookie) {
      this.authenticated = true;
      this.clearSession();
    }
    this.selectMenu();
    this.updateAiMenu();
  }

  selectMenu() {
    const route = this.$route.path;
    if (this.menuHome.routePath == route) {
      this.menuSelected = this.menuHome.index;
    }
    if (this.menuChiSiamo.routePath == route) {
      this.menuSelected = this.menuChiSiamo.index;
    }
    if (this.menuAreeTematiche.routePath == route) {
      this.menuSelected = this.menuAreeTematiche.index;
    }
    if (this.menuBancaDati.routePath == route) {
      this.menuSelected = this.menuBancaDati.index;
    }
    if (this.menuCodici.routePath == route) {
      this.menuSelected = this.menuCodici.index;
    }
    if (this.menuNews.routePath == route) {
      this.menuSelected = this.menuNews.index;
    }
    if (this.menuRealTime.routePath == route) {
      this.menuSelected = this.menuRealTime.index;
    }
    if (this.menuPubblicazioni.routePath == route) {
      this.menuSelected = this.menuPubblicazioni.index;
    }
    if (this.menuVideo.routePath == route) {
      this.menuSelected = this.menuVideo.index;
    }
    if (this.menuAgenda.routePath == route) {
      this.menuSelected = this.menuAgenda.index;
    }
    if (this.menuAbbonamenti.routePath == route) {
      this.menuSelected = this.menuAbbonamenti.index;
    }
  }

  async goToInstagram() {
    const route = this.$router.resolve({ name: "Instagram" });
    window.open(route.href, "_blank");
  }

  async goToFacebook() {
    const route = this.$router.resolve({ name: "Facebook" });
    window.open(route.href, "_blank");
  }

  async goToLinkedIn() {
    const route = this.$router.resolve({ name: "LinkedIn" });
    window.open(route.href, "_blank");
  }

  get info() {
    if (this.$store.getters.crmInfo) {
      return this.$store.getters.crmInfo;
    } else {
      return new User(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false
      );
    }
  }
}
