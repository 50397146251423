interface SearchDataProps {
  index: string;
  id?: string;
  target?: string;
  type?: string;
  keywords?: string[];
  queryType?: string;
  idCategory?: string[];
  year?: string;
  since?: string;
  fromDataFonte?: string;
  to?: string;
  from?: number;
  size?: number;
  perPage?: number;
  featured?: number;
  idAreaTematica?: string[];
  idMateria?: string[];
  idArgomento?: number[];
  idFonte?: number[];
  tags?: string[];
  orderingType?: string;
  withContent?: boolean;
  withText?: boolean;
  number?: string;
  searchScope?: string;
  globalSearch?: boolean;
  searchInMassime?: boolean;
  isSemantic?: boolean;
  sezioneCassazione?: string;
  annoGazzetta?: string;
  numeroGazzetta?: string;
  dataGazzetta?: string;
  citta?: string;
  regione?: string;
  causa?: string;
  ricorso?: string;
}

export class SearchData {
  private props: SearchDataProps;

  constructor(props: SearchDataProps) {
    this.props = props;
  }

  get properties(): SearchDataProps {
    return this.props;
  }

  // GETTERs and SETTERs
  get index(): string {
    return this.props.index;
  }
  set index(index: string) {
    if (index) {
      this.props.index = index;
    }
  }
  get id(): string | undefined {
    return this.props.id;
  }
  get target(): string | undefined {
    return this.props.target;
  }
  get type(): string | undefined {
    return this.props.type;
  }
  get keywords(): string[] | undefined {
    return this.props.keywords;
  }
  set keywords(keywords: string[] | undefined) {
    this.props.keywords = keywords;
  }
  get queryType(): string | undefined {
    return this.props.queryType;
  }
  set queryType(queryType: string | undefined) {
    if (queryType) {
      this.props.queryType = queryType;
    }
  }
  get idCategory(): string[] | undefined {
    return this.props.idCategory;
  }
  set idCategory(idCategory: string[] | undefined) {
    this.props.idCategory = idCategory;
  }
  get idFonte(): number[] | undefined {
    return this.props.idFonte;
  }
  set idFonte(idFonte: number[] | undefined) {
    this.props.idFonte = idFonte;
  }
  get year(): string | undefined {
    return this.props.year;
  }
  set year(year: string | undefined) {
    this.props.year = year;
  }
  get annoGazzetta(): string | undefined {
    return this.props.annoGazzetta;
  }
  set annoGazzetta(annoGazzetta: string | undefined) {
    this.props.annoGazzetta = annoGazzetta;
  }
  get since(): string | undefined {
    return this.props.since;
  }
  set since(since: string | undefined) {
    this.props.since = since;
  }
  get to(): string | undefined {
    return this.props.to;
  }
  set to(to: string | undefined) {
    this.props.to = to;
  }
  get from(): number | undefined {
    return this.props.from;
  }
  get dataGazzetta(): string | undefined {
    return this.props.dataGazzetta;
  }
  set dataGazzetta(dataGazzetta: string | undefined) {
    this.props.dataGazzetta = dataGazzetta;
  }
  get size(): number | undefined {
    return this.props.size;
  }
  set size(size: number | undefined) {
    this.props.size = size;
  }
  get perPage(): number | undefined {
    return this.props.perPage;
  }
  set perPage(perPage: number | undefined) {
    this.props.perPage = perPage;
  }
  get featured(): number | undefined {
    return this.props.featured;
  }
  get idAreaTematica(): string[] | undefined {
    return this.props.idAreaTematica;
  }
  set idAreaTematica(idAreaTematica: string[] | undefined) {
    this.props.idAreaTematica = idAreaTematica;
  }
  get idMateria(): string[] | undefined {
    return this.props.idMateria;
  }
  set idMateria(idMateria: string[] | undefined) {
    this.props.idMateria = idMateria;
  }
  get idArgomento(): number[] | undefined {
    return this.props.idArgomento;
  }
  set idArgomento(idArgomento: number[] | undefined) {
    this.props.idArgomento = idArgomento;
  }

  get number(): string | undefined {
    return this.props.number;
  }
  set number(number: string | undefined) {
    this.props.number = number;
  }
  get numeroGazzetta(): string | undefined {
    return this.props.numeroGazzetta;
  }
  set numeroGazzetta(numeroGazzetta: string | undefined) {
    this.props.numeroGazzetta = numeroGazzetta;
  }
  get tags(): string[] | undefined {
    return this.props.tags;
  }
  set tags(tags: string[] | undefined) {
    if (tags) {
      this.props.tags = tags;
    }
  }
  get fromDataFonte(): string | undefined {
    return this.props.fromDataFonte;
  }
  get fromTime(): string | undefined {
    return this.props.orderingType;
  }
  get orderingType(): string | undefined {
    return this.props.orderingType;
  }
  set orderingType(orderingType: string | undefined) {
    this.props.orderingType = orderingType;
  }
  get withContent(): boolean | undefined {
    return this.props.withContent;
  }
  get withText(): boolean | undefined {
    return this.props.withText;
  }
  set withText(withText: boolean | undefined) {
    this.props.withText = withText;
  }
  get searchScope(): string | undefined {
    return this.props.searchScope;
  }
  get globalSearch(): boolean {
    return this.props.globalSearch ? this.props.globalSearch : false;
  }
  set globalSearch(globalSearch: boolean) {
    this.props.globalSearch = globalSearch;
  }
  get searchInMassime(): boolean {
    return this.props.searchInMassime ? this.props.searchInMassime : false;
  }
  set searchInMassime(searchInMassime: boolean) {
    this.props.searchInMassime = searchInMassime;
  }
  get isSemantic(): boolean {
    return this.props.isSemantic ? this.props.isSemantic : false;
  }
  set isSemantic(isSemantic: boolean) {
    this.props.isSemantic = isSemantic;
  }
  get sezioneCassazione(): string | undefined {
    return this.props.sezioneCassazione;
  }
  set sezioneCassazione(sezioneCassazione: string | undefined) {
    this.props.sezioneCassazione = sezioneCassazione;
  }
  get citta(): string | undefined {
    return this.props.citta;
  }
  set citta(citta: string | undefined) {
    this.props.citta = citta;
  }
  get regione(): string | undefined {
    return this.props.regione;
  }
  set regione(regione: string | undefined) {
    this.props.regione = regione;
  }
  get causa(): string | undefined {
    return this.props.causa;
  }
  set causa(causa: string | undefined) {
    this.props.causa = causa;
  }
  get ricorso(): string | undefined {
    return this.props.ricorso;
  }
  set ricorso(ricorso: string | undefined) {
    this.props.ricorso = ricorso;
  }

  clone(): SearchData {
    return new SearchData({
      index: this.index,
      id: this.id,
      target: this.target,
      type: this.type,
      keywords: this.keywords,
      queryType: this.queryType,
      idCategory: this.idCategory,
      year: this.year,
      since: this.since,
      fromDataFonte: this.fromDataFonte,
      to: this.to,
      from: this.from,
      size: this.size,
      perPage: this.perPage,
      featured: this.featured,
      idAreaTematica: this.idAreaTematica,
      idMateria: this.idMateria,
      idArgomento: this.idArgomento,
      idFonte: this.idFonte,
      tags: this.tags,
      orderingType: this.orderingType,
      withContent: this.withContent,
      withText: this.withText,
      number: this.number,
      searchScope: this.searchScope,
      globalSearch: this.globalSearch,
      searchInMassime: this.searchInMassime,
      isSemantic: this.isSemantic,
      sezioneCassazione: this.sezioneCassazione,
      annoGazzetta: this.annoGazzetta,
      dataGazzetta: this.dataGazzetta,
      numeroGazzetta: this.numeroGazzetta,
      citta: this.citta,
      regione: this.regione
    });
  }
}
